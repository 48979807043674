<template>
  <div id="container" class="map-wrapper">
    <h3 class="poi-title">नक्शामा आकर्षणका केन्द्रहरु</h3>
    <div id="mapContainer"></div>
  </div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { getPoiTypes } from "./../helper/helper";
import { englishToNepaliNumber } from "nepali-number";
import markerIcon from "./../assets/marker.svg";
import bheri from "./../assets/geojson/bheri.json";
import triveni from "./../assets/geojson/triveni.json";
import kapurkot from "./../assets/geojson/kapurkot.json";
import khandachakra from "./../assets/geojson/khandachakra.json";
import siddhakumakh from "./../assets/geojson/siddhakumakh.json";
import shibalaya from "./../assets/geojson/shibalaya.json";

export default {
  name: "Map",
  props: {
    mapData: { type: Array, required: false },
  },
  data() {
    return {
      map: null, // leaflet map instance
      layers: [],
      layerGroup: null,
      polylineLayer: null,
    };
  },
  mounted() {
    this.setupLeafletMap();
  },
  methods: {
    /**
     * Setup the leaflet instance
     */
    setupLeafletMap() {
      this.map = L.map("mapContainer");
      L.tileLayer(
        "https://api.mapbox.com/styles/v1/rumus007/ckuggnt2w0xi617pf0jtanr2s/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicnVtdXMwMDciLCJhIjoiY2txYWlqMjVoMGFsdDJ5cXNsbmttbzZ2dCJ9.ljs_kQoEJQ7xqu1LCG3XtQ",
        {
          attribution:
            '&copy <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        }
      ).addTo(this.map);
      let jsonLayer;
      switch (process.env.VUE_APP_LOCAL_GOV) {
        case "bheri":
          jsonLayer = L.geoJSON(bheri).addTo(this.map);
          break;
        case "triveni":
          jsonLayer = L.geoJSON(triveni).addTo(this.map);
          break;
        case "kapurkot":
          jsonLayer = L.geoJSON(kapurkot).addTo(this.map);
          break;
        case "khadachakra":
          jsonLayer = L.geoJSON(khandachakra).addTo(this.map);
          break;
        case "siddhakumakh":
          jsonLayer = L.geoJSON(siddhakumakh).addTo(this.map);
          break;
        case "shibalaya":
          jsonLayer = L.geoJSON(shibalaya).addTo(this.map);
          break;
      }
      this.map.fitBounds(jsonLayer.getBounds());
      this.addMarker();
    },
    /**
     * Adds marker in maps
     */
    addMarker() {
      this.layerGroup = L.layerGroup().addTo(this.map);

      this.mapData.map((item) => {
        //check if geolocation latitude and longitude values are null
        var isNull = item._geolocation.some((ele) => ele === null);

        if (!isNull) {
          L.marker(item._geolocation, {
            icon: L.icon({
              iconUrl: markerIcon,
              iconSize: [24, 30],
            }),
          })
            .bindPopup(this.getToolTipContent(item))
            .addTo(this.layerGroup);
        }
      });
    },
    /**
     * Display tooltip
     */
    getToolTipContent(data) {
      let imgsrc =
        data._attachments.length > 0
          ? `${data._attachments[0].download_medium_url}`
          : "#";
      return `<div class="rotate-img"><img class="img" src="${imgsrc}">
              </div>
              <div class="popup">${this.getPoiWiseContent(data)}</div>`;
    },
    /**
     * Display data in tooltip according to poi type
     */
    getPoiWiseContent(data) {
      let mappedValues = getPoiTypes(data);

      let popup = `<div class="main-info">
        <div class="poi-detail-title pd-bottom-28">
          <div class="heading">${mappedValues.name_nep}</div>
          <div class="geo-location">
            N${mappedValues._geolocation.lat}" E${mappedValues._geolocation.long}"
          </div>
        </div><div class="other-info-wrapper"><div class="other-info">`;

      for (const key in mappedValues.misc) {
        popup += `<dl><dt>${key}:</dt> <dd>${
          mappedValues.misc[key] || "-"
        }</dd></dl>`;
      }

      popup += `</div>
      <div class="contact">
        <div class="contact-person"> ${mappedValues.contact_person}</div>
        <div class="contact-number">${
          mappedValues.contact_number
            ? englishToNepaliNumber(mappedValues.contact_number)
            : "-"
        }</div>
      </div></div></div>`;

      return popup;
    },

    /**
     * Resets the markers
     */
    resetMarker() {
      this.layerGroup.clearLayers();
      this.addMarker();
    },
  },
  watch: {
    mapData: {
      handler: "resetMarker",
      deep: true,
    },
  },
};
</script>

<style scoped lang="scss">
#mapContainer {
  height: 790px;
  z-index: 0;
}

.map-wrapper {
  margin-left: 32px;
  width: calc(100% - 272px);
  &__search-input-wrapper {
    left: 70px;
    position: absolute;
    top: 10px;
    z-index: 1;
  }
}

.map-search {
  &__icon {
    left: 33px;
    position: absolute;
    top: 19px;
  }

  &__input {
    border: 0;
    border-radius: 40px;
    outline: none;
    padding: 22px 40px 22px 58px;
    width: 420px;
  }
}

.poi-title {
  background: $primary;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: $neutrals-white;
  padding: 14px 24px;
}
</style>
